import BaseService from './base.service';
class AvaliacaoService extends BaseService {
    constructor() {
        super({ resource: 'avaliacao' });
    }
    async getBonds(avaliacaoId) {
        return this.plantarApi.get(`${this.resource}/vinculos/${avaliacaoId}`);
    }
    async getInfo(opt) {
        try {
            const { success, data } = await this.plantarApi.get([this.resource, 'pre', opt.sourceType, opt.sourceId, opt.testId].join('/'));
            if (success) {
                return {
                    success,
                    data: {
                        id: opt.testId,
                        title: data.titulo,
                        observation: data.observacao,
                        maxQuestions: data.totalQuestoes,
                        duration: data.duracaoDaAvaliacao
                            ?.split(':')
                            .reverse()
                            .reduce((prev, curr, i) => prev + parseInt(curr) * Math.pow(60, i), 0) || 0,
                        canRetry: data.politicaRetentativa,
                        retries: data.tentativasRealizadas,
                        maxRetries: data.maximoTentativas,
                        latestRetry: null,
                        score: data.notaDaAvaliacao,
                        type: ['Diagnostica', 'Reacao', 'Conhecimento', 'Aplicabilidade', 'Resultado'].findIndex((val) => val === data.tipoDeAvaliacao),
                        displayAnswers: data.exibirCorretaseErradas,
                        showTimer: data.exibirTimer
                    }
                };
            }
        }
        catch (error) {
            //
        }
        return {
            success: false
        };
    }
    async start(opt) {
        try {
            const { success, data } = await this.plantarApi.post([this.resource, 'iniciar', opt.sourceType, opt.sourceId, opt.testId].join('/'));
            return {
                success,
                data: {
                    id: data.id
                }
            };
        }
        catch (error) {
            //
        }
        return { success: false };
    }
    async nextQuestion(userTestId) {
        return this.plantarApi.get(`${this.resource}/proximapergunta/${userTestId}`);
    }
    async answer(answer) {
        try {
            const { success, data } = await this.plantarApi.post(`${this.resource}/responder`, {
                usuarioAvaliacaoId: answer.userTestId,
                questaoId: answer.questionId,
                respostasId: answer.answersId,
                respostaAvaliacaoReacao: answer.answerDiscourse,
                respostaAvaliacaoEscala: answer.scale
            });
            return {
                success,
                data: {
                    correct: data.acertou,
                    correctAnswers: data.respostasCorretas,
                    userAnswers: data.usuariosAvaliacoesRespostas,
                    scale: data.respostaAvaliacaoEscala,
                    answerDiscourse: data.respostaAvaliacaoReacao,
                    hit: data.feedbackAcerto,
                    miss: data.feedbackErro
                }
            };
        }
        catch (error) {
            return { success: false };
        }
    }
    async results(userTestId) {
        try {
            const { success: s1, data: result } = await this.plantarApi.post(`${this.resource}/finalizar/${userTestId}`);
            const { success: s2, data: info } = await this.getInfo({
                sourceType: result.cursoId ? 'curso' : 'formacao',
                sourceId: result.cursoId || result.formacaoId,
                testId: result.avaliacaoId
            });
            return {
                success: s1 && s2,
                data: {
                    userTestId: result.usuarioAvaliacaoId,
                    title: info.title,
                    completed: result.concluida,
                    retries: info.retries,
                    maxRetries: info.maxRetries,
                    date: new Date(result.dataAvaliacao),
                    score: result.nota,
                    duration: result.tempoGastoEmMinutos,
                    approved: result.aprovado,
                    hit: result.acertos,
                    miss: result.erros,
                    type: info.type,
                    courseCompleted: result?.aprovacao?.status === 'Aprovado'
                }
            };
        }
        catch (error) {
            return { success: false };
        }
    }
    async giveUp(testId) {
        if (!testId)
            return Promise.resolve({ success: false });
        try {
            const { success } = await this.plantarApi.put(`${this.resource}/desistencia/${testId}`);
            return { success };
        }
        catch (error) {
            return { success: false };
        }
    }
    async dontAnswerTest(sourceType, sourceId, avaliacaoId) {
        try {
            const { success, data } = await this.plantarApi.post(['usuarioAvaliacao', 'dontAnswerEvalution', sourceType, sourceId, avaliacaoId].join('/'));
            return { success, data };
        }
        catch (error) {
            //
        }
        return { success: false };
    }
}
const avaliacaoService = new AvaliacaoService();
export default avaliacaoService;
