import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, unref as _unref, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, createBlock as _createBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "max-w-screen-lg mx-auto flex flex-col md:flex-row p-4 gap-4 rounded-lg bg-white dark:bg-neutral-600"
};
const _hoisted_2 = { class: "inline-block w-32 h-32 border-2 border-neutral-500/25 rounded-lg overflow-hidden" };
const _hoisted_3 = ["src"];
const _hoisted_4 = { class: "grow flex flex-col" };
const _hoisted_5 = { class: "rounded-lg overflow-hidden" };
const _hoisted_6 = {
    key: 1,
    class: "grow"
};
const _hoisted_7 = ["src"];
const _hoisted_8 = ["label", "srclang", "src"];
const _hoisted_9 = {
    key: 3,
    class: "flex text-center justify-center"
};
const _hoisted_10 = { class: "hidden sm:inline" };
const _hoisted_11 = ["data-plyr-provider", "data-plyr-embed-id"];
const _hoisted_12 = ["src"];
const _hoisted_13 = {
    key: 6,
    class: "text-center"
};
const _hoisted_14 = ["src"];
import { onBeforeUnmount, onMounted, ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import Hls from 'hls.js';
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';
import { midiaService } from '@/services/midias.service';
import { useSystemStore } from '@/stores';
import { languageType } from '@/common/util';
import playerConfig from './playerConfig';
import UiButton from '@/components/ui/button.vue';
import UiPdfViewer from '@/components/ui/pdfViewer/index.vue';
import AudioImg from '@/assets/placeholder/audio.jpg';
export default /*@__PURE__*/ _defineComponent({
    __name: 'player',
    props: {
        source: {},
        type: {},
        videoId: {},
        pullzone: {},
        progress: {},
        chapters: {},
        captions: {},
        allowDownload: { type: Boolean },
        watermark: { type: Boolean },
        watermarkRules: {},
        disableFastForward: { type: Boolean },
        objData: {}
    },
    emits: ['onEnded', 'onUpdateProgress', 'onLoadFrame', 'onScreenshot'],
    setup(__props, { emit: __emit }) {
        const { t } = useI18n();
        const props = __props;
        const emit = __emit;
        let hls = null;
        let player = null;
        const viewingImage = ref(false);
        const pdfViewerRef = ref();
        const playerRef = ref();
        // Armazena o progresso já percorrido pelo vídeo
        let watchedTime = 0;
        // const autoCaptions = ref([])
        const autoChapters = ref([]);
        const doneLoading = ref(false);
        const progressTrack = {
            blocked: false,
            value: 0,
            timeout: null
        };
        const fileExtension = computed(() => props.source?.split('.').at(-1));
        const getDocumentLink = computed(() => {
            if (['document', 'documento'].includes(props.type) && ['xls', 'xlsx', 'pptx', 'ppt', 'doc', 'docx', 'odp', 'ods', 'odt'].includes(fileExtension.value)) {
                return `https://view.officeapps.live.com/op/embed.aspx?src=${props.source}`;
            }
            else if (props.type === 'link' && props.source.includes('soundcloud')) {
                return `https://w.soundcloud.com/player/?url=${props.source}&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true`;
            }
            else
                return props.source;
        });
        const getVideoId = computed(() => {
            if (props.source?.includes('youtu')) {
                let temp = null;
                temp = props.source.includes('v=') ? props.source.split('=')[1] : props.source.split('/').at(-1);
                return temp.includes('&') ? temp.split('&')[0] : temp;
            }
            else {
                return props.source.split('/').at(-1);
            }
        });
        watch(() => [pdfViewerRef.value?.page, pdfViewerRef.value?.pageCount], (nVal, oVal) => {
            if (!oVal[1] && !!nVal[1] && nVal[1] > 1 && props.progress > 0 && props.progress < 100) {
                pdfViewerRef.value?.jumpToPercent(props.progress);
            }
            if (pdfViewerRef.value?.pageCount) {
                const percent = Math.round((pdfViewerRef.value.page / pdfViewerRef.value.pageCount) * 100);
                percent > props.progress && updateProgress(percent, true);
            }
        });
        const getVideoInfo = async () => {
            doneLoading.value = false;
            try {
                const { data: videoData } = await midiaService.getVideoInfo(props.videoId);
                // autoCaptions.value = []
                autoChapters.value = [];
                if (videoData) {
                    autoChapters.value = videoData.chapters.map((chap) => ({
                        start: chap.start,
                        end: chap.end,
                        title: chap.title
                    }));
                }
            }
            catch (error) {
                //    
            }
            finally {
                doneLoading.value = true;
                createButtonScreenshot();
            }
        };
        const handleLinkClick = (source) => {
            window.open(source, '_blank');
        };
        const onLoadFrame = () => {
            if (props.type === 'scorm')
                emit('onLoadFrame');
        };
        const updateProgress = async (value, force) => {
            if (!force) {
                if (progressTrack.blocked)
                    return;
                setTimeout(() => {
                    progressTrack.blocked = false;
                }, 10000);
                progressTrack.blocked = true;
                progressTrack.value = value;
            }
            emit('onUpdateProgress', value);
        };
        const updateQuality = (newQuality) => {
            hls.levels.forEach((level, levelIndex) => {
                if (level.height === newQuality) {
                    hls.currentLevel = levelIndex;
                }
            });
        };
        const initPlyr = async (config) => {
            if (player)
                player.destroy();
            await getVideoInfo();
            playerConfig.autoplay = useSystemStore().options.autoplay;
            playerConfig.volume = useSystemStore().options.volume;
            const controls = ['play-large', 'restart', 'rewind', 'fast-forward', 'play', 'progress', 'current-time', 'duration', 'mute', 'volume', 'captions', 'settings', 'pip', 'airplays', 'fullscreen'];
            const speed = {
                selected: 1,
                options: [0.5, 0.75, 1, 1.25, 1.5, 2]
            };
            if (props.disableFastForward) {
                controls.splice(controls.indexOf('fast-forward'), 1);
                speed.options = [0.5, 0.75, 1];
            }
            player = new Plyr(playerRef.value, {
                ...playerConfig,
                ...config,
                controls,
                speed,
                settings: ['captions', 'quality', 'speed'],
                captions: {
                    update: true,
                    active: (!!props.captions?.length)
                },
                markers: {
                    enabled: true,
                    points: props.chapters?.map(el => ({ time: el.start, label: el.title })) || autoChapters.value
                },
            });
            if (['audio', 'video'].includes(props.type))
                player.on('loadedmetadata', onLoadedMetadata);
            else
                player.on('ready', checkDurationRecursive);
            // Caso o usuário "avance" manualmente
            player.on('seeked', () => {
                if (props.disableFastForward && player.currentTime > watchedTime) {
                    player.currentTime = watchedTime;
                }
            });
            player.on('timeupdate', onTimeUpdate);
            player.on('ended', onEnded);
            return player;
        };
        let watchedTM = null;
        const onTimeUpdate = () => {
            if (watchedTM)
                clearTimeout(watchedTM);
            watchedTM = setTimeout(() => {
                if (player.playing && watchedTime < player.currentTime) {
                    watchedTime = player.currentTime;
                }
            }, 100);
            let percent = player.currentTime && player.duration ? ((player.currentTime * 100) / player.duration) : 0;
            if ((player.currentTime >= (player.duration - 5)) && progressTrack?.value !== 100 && props.type === 'video') {
                progressTrack.blocked = false;
                percent = 100;
                emit('onEnded');
            }
            updateProgress(percent);
        };
        const onEnded = () => {
            if (progressTrack.value === 100)
                return;
            updateProgress(100, true);
            emit('onEnded');
        };
        const checkDurationRecursive = (event) => {
            if (player.duration === 0)
                setTimeout(() => {
                    return checkDurationRecursive(event);
                }, 50);
            else
                return onLoadedMetadata(event);
        };
        const onLoadedMetadata = (event) => {
            if (props.progress > 0 && props.progress < 100)
                player.currentTime = (props.progress / 100) * event.detail.plyr.duration;
        };
        const initHls = async () => {
            if (!Hls.isSupported())
                throw t('errors.notSupportStreaming');
            if (hls)
                hls.destroy();
            if (!props.source)
                return;
            hls = new Hls();
            hls.loadSource(`//${props.source}`);
            // Promise utilizada para manter o fluxo sincrono
            new Promise((resolve) => {
                // Lê o manifest da playlist m3u8 (Importante para extrari informações para o Plyr)
                hls.on(Hls.Events.MANIFEST_PARSED, () => {
                    const availableQualities = hls.levels.map((l) => l.height);
                    playerConfig.quality = {
                        default: availableQualities.find((el) => el === 1080) || availableQualities[availableQualities.length - 1],
                        options: availableQualities,
                        forced: true,
                        onChange: (e) => updateQuality(e)
                    };
                    initPlyr(playerConfig);
                    player.on('languagechange', () => {
                        setTimeout(() => (hls.subtitleTrack = player.currentTrack), 50);
                    });
                    resolve(true);
                });
                hls.attachMedia(playerRef.value);
            });
        };
        const initSoundCloudPlayer = () => {
            const SC = window.SC;
            const widget = SC.Widget(document.getElementById('player-frame'));
            widget.bind(SC.Widget.Events.PLAY_PROGRESS, ({ relativePosition }) => {
                relativePosition > 0 && updateProgress(Math.round((relativePosition * 100)));
            });
            widget.bind(SC.Widget.Events.FINISH, () => {
                updateProgress(100, true);
                emit('onEnded');
            });
        };
        const loadMediaLink = async () => {
            props.source.includes('soundcloud') ? initSoundCloudPlayer() : initPlyr();
        };
        const loadMediaVideo = async () => {
            Hls.isSupported() && !props.source.includes('iframe') ? initHls() : initPlyr();
        };
        const loadMediaAudio = async () => {
            await initPlyr();
            player.source = {
                type: 'audio',
                title: '',
                sources: [
                    {
                        src: props.source,
                        type: 'audio/mp3'
                    }
                ]
            };
        };
        const captureFrame = () => {
            let md = player?.media;
            if (!md)
                return;
            const width = md.videoWidth;
            const height = md.videoHeight;
            const canvas = Object.assign(document.createElement('canvas'), { width, height });
            const canvasCtx = canvas.getContext('2d');
            const img = new Image();
            canvasCtx.drawImage(md, 0, 0, width, height);
            img.setAttribute('crossOrigin', 'anonymous');
            img.onload = () => {
                const imageURl = img.src.replace(/^data:image\/[^;]+/, 'data:application/octet-stream');
                emit('onScreenshot', imageURl);
            };
            img.src = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
        };
        const createButtonScreenshot = () => {
            if (props.type !== 'video')
                return;
            setTimeout(() => {
                const menu = document.querySelector('.plyr__controls__item.plyr__menu');
                if (!menu)
                    return;
                const btn = `
      <button class="plyr__controls__item plyr__control" type="button" data-plyr="capture">
        <svg role="presentation" focusable="false">
          <path d="M9.098,7.566c0.758,0,1.408,0.27,1.946,0.809c0.539,0.538,0.809,1.188,0.809,1.946c0,0.758-0.27,1.406-0.809,1.946
          c-0.538,0.539-1.188,0.81-1.946,0.81c-0.759,0-1.408-0.271-1.947-0.81c-0.539-0.54-0.808-1.188-0.808-1.946
          c0-0.759,0.27-1.408,0.808-1.946C7.689,7.835,8.339,7.566,9.098,7.566z M14.862,3.471c0.59,0,1.093,0.241,1.511,0.723
          C16.79,4.676,17,5.258,17,5.939v8.646c0,0.682-0.21,1.264-0.627,1.747c-0.418,0.481-0.921,0.724-1.511,0.724H3.107
          c-0.59,0-1.095-0.242-1.511-0.724c-0.417-0.483-0.627-1.065-0.627-1.747V5.939c0-0.682,0.21-1.264,0.627-1.746
          c0.417-0.482,0.921-0.723,1.511-0.723h1.87l0.426-1.313c0.105-0.315,0.299-0.587,0.58-0.815C6.264,1.114,6.552,1,6.846,1h4.274
          c0.297,0,0.584,0.114,0.866,0.342c0.28,0.228,0.475,0.5,0.58,0.815l0.424,1.313H14.862L14.862,3.471z M9.098,14.606
          c1.179,0,2.188-0.419,3.026-1.258c0.84-0.839,1.258-1.847,1.258-3.027c0-1.18-0.418-2.188-1.258-3.027
          c-0.839-0.839-1.848-1.259-3.026-1.259c-1.18,0-2.189,0.42-3.028,1.259c-0.838,0.839-1.258,1.847-1.258,3.027
          c0,1.181,0.42,2.188,1.258,3.027C6.909,14.188,7.917,14.606,9.098,14.606z"/>
        </svg>
      </button>
    `;
                menu.insertAdjacentHTML('beforebegin', btn);
                const btnElement = document.querySelector('button[data-plyr="capture"]');
                btnElement.addEventListener('click', captureFrame);
            }, 1000);
        };
        onMounted(async () => {
            switch (props.type) {
                case 'link':
                    await loadMediaLink();
                    break;
                case 'video':
                    await loadMediaVideo();
                    break;
                case 'audio':
                    await loadMediaAudio();
                    break;
            }
        });
        onBeforeUnmount(() => {
            try {
                if (player)
                    player.destroy();
                if (hls)
                    hls.destroy();
            }
            catch (error) {
                //
            }
        });
        return (_ctx, _cache) => {
            const _component_VueEasyLightbox = _resolveComponent("VueEasyLightbox");
            return (_openBlock(), _createElementBlock("div", null, [
                (_ctx.type === 'audio')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        (_ctx.$slots.default)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createElementVNode("div", _hoisted_2, [
                                    _createElementVNode("div", null, [
                                        _renderSlot(_ctx.$slots, "cover", {}, () => [
                                            _createElementVNode("img", {
                                                src: _unref(AudioImg),
                                                class: "w-full h-full aspect-square object-cover"
                                            }, null, 8, _hoisted_3)
                                        ])
                                    ])
                                ]),
                                _createElementVNode("div", _hoisted_4, [
                                    _renderSlot(_ctx.$slots, "default"),
                                    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "grow" }, null, -1)),
                                    _createElementVNode("div", _hoisted_5, [
                                        _createElementVNode("audio", {
                                            ref_key: "playerRef",
                                            ref: playerRef
                                        }, null, 512)
                                    ])
                                ])
                            ], 64))
                            : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                _createElementVNode("audio", {
                                    ref_key: "playerRef",
                                    ref: playerRef
                                }, null, 512)
                            ]))
                    ]))
                    : (_ctx.type === 'video' && !_ctx.source.includes('iframe'))
                        ? (_openBlock(), _createElementBlock("video", {
                            key: 1,
                            ref_key: "playerRef",
                            ref: playerRef,
                            controls: "",
                            crossorigin: "anonymous",
                            class: "w-full h-full bg-black"
                        }, [
                            _createElementVNode("source", {
                                src: `https://${_ctx.pullzone}/${_ctx.videoId}/playlist.m3u8`,
                                type: "application/x-mpegURL"
                            }, null, 8, _hoisted_7),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.captions, (cap) => {
                                return (_openBlock(), _createElementBlock("track", {
                                    kind: "captions",
                                    key: cap.srclang,
                                    label: _unref(languageType)[cap.srclang?.toLowerCase()],
                                    srclang: cap.srclang?.toLowerCase(),
                                    src: `https://${_ctx.pullzone}/${_ctx.videoId}/captions/${cap.srclang}.vtt`
                                }, null, 8, _hoisted_8));
                            }), 128))
                        ], 512))
                        : (_ctx.type === 'pdf' && _ctx.source)
                            ? (_openBlock(), _createBlock(UiPdfViewer, {
                                key: 2,
                                ref_key: "pdfViewerRef",
                                ref: pdfViewerRef,
                                source: _ctx.source,
                                "allow-download": _ctx.allowDownload,
                                watermark: _ctx.watermark,
                                "watermark-rules": _ctx.watermarkRules
                            }, null, 8, ["source", "allow-download", "watermark", "watermark-rules"]))
                            : (_ctx.type === 'externalLink')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                    _createVNode(UiButton, {
                                        type: "primary",
                                        class: "h-12 px-4",
                                        onClick: _cache[0] || (_cache[0] = ($event) => (handleLinkClick(_ctx.source)))
                                    }, {
                                        default: _withCtx(() => [
                                            _createElementVNode("span", _hoisted_10, _toDisplayString(_unref(t)('button.accessLink')), 1)
                                        ]),
                                        _: 1
                                    })
                                ]))
                                : (_ctx.type === 'link' && !_ctx.source.includes('soundcloud'))
                                    ? (_openBlock(), _createElementBlock("div", {
                                        key: 4,
                                        ref_key: "playerRef",
                                        ref: playerRef,
                                        "data-plyr-provider": _ctx.source.includes('youtu') ? 'youtube' : 'vimeo',
                                        "data-plyr-embed-id": getVideoId.value,
                                        class: "w-full h-full"
                                    }, null, 8, _hoisted_11))
                                    : (['scorm', 'document', 'documento'].includes(_ctx.type) || (_ctx.type === 'link' && _ctx.source.includes('soundcloud'))
                                        || (_ctx.type === 'video' && props.source.includes('iframe')))
                                        ? (_openBlock(), _createElementBlock("iframe", {
                                            key: 5,
                                            id: "player-frame",
                                            src: getDocumentLink.value,
                                            onLoad: _cache[1] || (_cache[1] = () => onLoadFrame()),
                                            class: "border-0 w-full h-full"
                                        }, null, 40, _hoisted_12))
                                        : (['image', 'imagem', 'img'].includes(_ctx.type))
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                                _createElementVNode("img", {
                                                    src: _ctx.source,
                                                    alt: "Player image",
                                                    class: "max-w-[1024px] mx-auto cursor-pointer hover:ring-2 ring-offset-2 transition-all rounded",
                                                    onClick: _cache[2] || (_cache[2] = () => viewingImage.value = true)
                                                }, null, 8, _hoisted_14),
                                                _createVNode(_component_VueEasyLightbox, {
                                                    visible: viewingImage.value,
                                                    imgs: [_ctx.source],
                                                    index: 0,
                                                    onHide: _cache[3] || (_cache[3] = () => viewingImage.value = false)
                                                }, null, 8, ["visible", "imgs"])
                                            ]))
                                            : _createCommentVNode("", true)
            ]));
        };
    }
});
