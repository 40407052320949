import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, createVNode as _createVNode, withModifiers as _withModifiers, createBlock as _createBlock, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, createTextVNode as _createTextVNode, vShow as _vShow, vModelRadio as _vModelRadio, normalizeStyle as _normalizeStyle, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "w-full h-full items-center gap-2 text-[#BBBBBB] truncate"
};
const _hoisted_2 = {
    key: 1,
    class: "w-full max-w-[calc(100%-24px)] flex gap-2 relative select-none"
};
const _hoisted_3 = { class: "text-xs" };
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
    key: 0,
    class: "flex items-center justify-center rounded-full px-2 h-6 text-xs absolute right-2 top-0 bg-sky-200 text-sky-600"
};
const _hoisted_6 = {
    key: 2,
    class: "flex grow flex-wrap items-center justify-start gap-2"
};
const _hoisted_7 = {
    key: 3,
    class: "self-end flex gap-2 shrink-0"
};
const _hoisted_8 = {
    key: 0,
    class: "flex items-center justify-end gap-2 px-1 mb-2"
};
const _hoisted_9 = ["placeholder"];
const _hoisted_10 = {
    key: 0,
    class: "box-border flex h-full flex-col"
};
const _hoisted_11 = { class: "item" };
const _hoisted_12 = { class: "item" };
const _hoisted_13 = ["value"];
const _hoisted_14 = {
    key: 1,
    class: "box-border flex h-full flex-col"
};
const _hoisted_15 = ["value"];
const _hoisted_16 = {
    key: 1,
    class: "text-center text-neutral-500"
};
const _hoisted_17 = {
    key: 2,
    class: "text-center text-neutral-500"
};
import { computed, inject, onMounted, onUnmounted, ref, watch, nextTick } from 'vue';
import { useElementBounding } from '@vueuse/core';
import { strSearch } from '@/common/util';
import UiDropdown from '@/components/ui/dropdown.vue';
import UiIcon from '@/components/ui/icon.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'select',
    props: /*@__PURE__*/ _mergeModels({
        multiple: { type: Boolean },
        placeholder: {},
        placement: {},
        clearable: { type: Boolean },
        searchable: { type: [String, Boolean], default: 'auto' },
        searchableFields: {},
        data: {},
        disabled: { type: Boolean },
        size: {},
        closeOnClick: { type: Boolean, default: true },
        selectedOnTop: { type: Boolean, default: true },
        loading: { type: Boolean },
        activeFilter: { type: Boolean }
    }, {
        "modelValue": {},
        "modelModifiers": {},
    }),
    emits: /*@__PURE__*/ _mergeModels(['change'], ["update:modelValue"]),
    setup(__props, { emit: __emit }) {
        const emit = __emit;
        const props = __props;
        const model = _useModel(__props, "modelValue");
        watch(() => model.value, (val) => {
            emit('change', val);
        });
        const inputSearch = ref();
        const validation = inject('validation', {});
        const fcProp = inject('fc-prop', '');
        const dropdown = ref();
        const listItems = ref();
        const showDropdown = ref(false);
        const search = ref();
        const inputSelectRef = ref();
        const selector = ref();
        const { width } = useElementBounding(selector);
        const normalizedData = computed(() => {
            return props.data?.map((el) => {
                if (typeof el === 'number' || typeof el === 'string')
                    return { label: el.toString(), value: el.toString() };
                if (typeof el === 'object' && el?.length === 2)
                    return { label: el[1], value: el[0] };
                return el;
            }) || [];
        });
        const listCopy = ref([]);
        const selection = computed(() => !props.multiple ? model.value : '');
        const selectedItems = computed(() => {
            if (props.multiple)
                return normalizedData.value.filter((el) => model.value?.includes(el.value)) || [];
            let res = normalizedData.value.find((el) => el.value === model.value);
            return res ? [res] : [];
        });
        // eslint-disable-next-line no-unused-vars
        const visibleTags = computed(() => {
            if (props.multiple) {
                return selectedItems.value.length > 2 ? selectedItems.value.slice(0, 2) : selectedItems.value;
            }
            return selectedItems.value;
        });
        const allSelected = computed({
            get: () => normalizedData.value.length === model.value?.length,
            set: (val) => {
                model.value = val ? model.value = listCopy.value.map((el) => el.value) : [];
            }
        });
        const visibleItems = computed(() => search.value
            ? listCopy.value.filter((el) => el && !props.searchableFields ? strSearch(el.label, search.value) : props.searchableFields.some((field) => strSearch(el[field], search.value)))
            : listCopy.value);
        const removeElement = (id) => {
            const index = model.value.findIndex((el) => el === id);
            if (index >= 0)
                model.value.splice(index, 1);
        };
        const clearSelected = () => {
            model.value = props.multiple ? [] : null;
        };
        watch(() => dropdown.value?.isOpen, (val) => {
            if (!val)
                return;
            nextTick(() => {
                inputSearch.value?.focus();
            });
            let list = normalizedData.value;
            // Move os itens selecionados para o topo da lista (se a propriedade selectedOnTop for true)
            if (props.selectedOnTop && props.multiple && Array.isArray(model.value) && list.length > 0) {
                let selItems = [];
                let unseltems = [];
                list.forEach((el) => {
                    if (typeof el?.value === 'undefined')
                        return;
                    if (model?.value.includes(el?.value)) {
                        selItems.push(el);
                    }
                    else {
                        unseltems.push(el);
                    }
                });
                list = selItems.concat(unseltems);
            }
            return listCopy.value = list;
        });
        const clickOutside = (e) => {
            if (showDropdown.value && !inputSelectRef.value.contains(e.target))
                showDropdown.value = false;
        };
        onMounted(() => {
            document.addEventListener('click', clickOutside, false);
        });
        onUnmounted(() => {
            document.removeEventListener('click', clickOutside, false);
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(UiDropdown, {
                ref_key: "dropdown",
                ref: dropdown,
                "close-on-click": false,
                disabled: _ctx.disabled
            }, {
                default: _withCtx(({ open }) => [
                    _renderSlot(_ctx.$slots, "selector", {
                        isSelected: (Array.isArray(__props.modelValue) ? __props.modelValue.length > 0 : __props.modelValue !== null && __props.modelValue !== undefined)
                    }, () => [
                        _createElementVNode("div", {
                            ref_key: "selector",
                            ref: selector,
                            class: _normalizeClass([
                                'ui-select box-border flex w-full cursor-pointer items-center gap-1 overflow-hidden', {
                                    'pointer-events-none !cursor-not-allowed': _ctx.disabled,
                                    'px-2 py-1': _ctx.size === 'small',
                                    'px-3 py-3 !h-[46px]': !_ctx.size || _ctx.size === 'medium',
                                    'px-4 py-5': _ctx.size === 'large',
                                    'error': _unref(validation)?.[_unref(fcProp)]?.$invalid,
                                    'active-filter': _ctx.activeFilter
                                }
                            ])
                        }, [
                            (!selectedItems.value.length)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.placeholder || ''), 1))
                                : _createCommentVNode("", true),
                            (_ctx.multiple && selectedItems.value.length)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                    _createElementVNode("div", {
                                        ref_key: "listItems",
                                        ref: listItems,
                                        class: "taglist snap-x flex items-center justify-start gap-2 max-w-[calc(100%-60px)] overflow-x-auto"
                                    }, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectedItems.value, (item) => {
                                            return (_openBlock(), _createElementBlock("div", {
                                                key: item.value,
                                                class: "snap-start item-tag gap-2 whitespace-nowrap"
                                            }, [
                                                _createElementVNode("div", _hoisted_3, _toDisplayString(item.label), 1),
                                                _createElementVNode("div", {
                                                    onClick: _withModifiers(() => removeElement(item.value), ["stop"])
                                                }, [
                                                    _createVNode(UiIcon, {
                                                        icon: "x",
                                                        size: "14px",
                                                        class: "cursor-pointer hover:text-red-400",
                                                        "stroke-width": "1.75"
                                                    })
                                                ], 8, _hoisted_4)
                                            ]));
                                        }), 128))
                                    ], 512),
                                    (selectedItems.value.length > 0)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(selectedItems.value.length), 1))
                                        : _createCommentVNode("", true)
                                ]))
                                : _createCommentVNode("", true),
                            (!_ctx.multiple && (model.value !== null && model.value !== undefined))
                                ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(normalizedData.value.find(el => el.value === selection.value)?.label), 1))
                                : _createCommentVNode("", true),
                            (!_ctx.disabled)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                    (_ctx.clearable && (_ctx.multiple ? model.value.length : model.value))
                                        ? (_openBlock(), _createBlock(UiIcon, {
                                            key: 0,
                                            icon: "circle-x",
                                            class: "text-[#FF4980] cursor-pointer",
                                            onClick: _withModifiers(clearSelected, ["stop"])
                                        }))
                                        : _createCommentVNode("", true),
                                    _createVNode(UiIcon, {
                                        icon: "chevron-down",
                                        class: _normalizeClass(["transition-all delay-75 cursor-pointer text-neutral-400", { 'rotate-180': open }])
                                    }, null, 8, ["class"])
                                ]))
                                : _createCommentVNode("", true)
                        ], 2)
                    ])
                ]),
                content: _withCtx(() => [
                    _createElementVNode("div", {
                        class: "p-1",
                        style: _normalizeStyle({ width: _unref(width) ? `${selector.value?.getBoundingClientRect().width}px` : 'auto' })
                    }, [
                        (_ctx.searchable === 'always' || (['auto', true].includes(_ctx.searchable) && listCopy.value.length > 4))
                            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                _createVNode(UiIcon, {
                                    icon: "search",
                                    size: "18px",
                                    class: "text-[--c-primary-800] dark:text-[--c-primary-600]"
                                }),
                                _withDirectives(_createElementVNode("input", {
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((search).value = $event)),
                                    ref_key: "inputSearch",
                                    ref: inputSearch,
                                    type: "text",
                                    class: "w-full box-border grow border-0 border-b border-b-neutral-400/50 text-neutral-800 bg-transparent pl-2 pr-7 py-1 text-base outline-none dark:text-[#FDFDFD]",
                                    placeholder: _ctx.$t('input.search')
                                }, null, 8, _hoisted_9), [
                                    [_vModelText, search.value]
                                ]),
                                (search.value)
                                    ? (_openBlock(), _createBlock(UiIcon, {
                                        key: 0,
                                        icon: "circle-x",
                                        size: "24px",
                                        class: "absolute cursor-pointer text-neutral-400",
                                        onClick: _cache[1] || (_cache[1] = _withModifiers(($event) => (search.value = ''), ["stop"]))
                                    }))
                                    : _createCommentVNode("", true)
                            ]))
                            : _createCommentVNode("", true),
                        _createElementVNode("div", {
                            class: "max-h-[168px] overflow-auto",
                            onClick: _cache[5] || (_cache[5] = _withModifiers(() => _ctx.closeOnClick && !_ctx.multiple && dropdown.value && dropdown.value.close(), ["stop"]))
                        }, [
                            (visibleItems.value.length)
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                    (_ctx.multiple)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                            _createElementVNode("div", null, [
                                                _createElementVNode("label", _hoisted_11, [
                                                    _withDirectives(_createElementVNode("input", {
                                                        type: "checkbox",
                                                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((allSelected).value = $event)),
                                                        value: true,
                                                        class: "input-checkbox"
                                                    }, null, 512), [
                                                        [_vModelCheckbox, allSelected.value]
                                                    ]),
                                                    _createTextVNode(" " + _toDisplayString(_ctx.$t('placeholder.select', { field: _ctx.$t('labels.all').toLowerCase() })), 1)
                                                ])
                                            ]),
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(visibleItems.value, (item) => {
                                                return (_openBlock(), _createElementBlock("div", {
                                                    key: item.value
                                                }, [
                                                    _createElementVNode("label", _hoisted_12, [
                                                        _withDirectives(_createElementVNode("input", {
                                                            type: "checkbox",
                                                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((model).value = $event)),
                                                            value: item.value,
                                                            class: "input-checkbox"
                                                        }, null, 8, _hoisted_13), [
                                                            [_vModelCheckbox, model.value]
                                                        ]),
                                                        _createTextVNode(" " + _toDisplayString(item.label), 1)
                                                    ])
                                                ]));
                                            }), 128))
                                        ]))
                                        : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(visibleItems.value, (item) => {
                                                return (_openBlock(), _createElementBlock("div", {
                                                    key: item.value
                                                }, [
                                                    _createElementVNode("label", {
                                                        class: _normalizeClass(['item', { 'active': item.value === model.value }])
                                                    }, [
                                                        _withDirectives(_createElementVNode("input", {
                                                            type: "radio",
                                                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((model).value = $event)),
                                                            value: item.value
                                                        }, null, 8, _hoisted_15), [
                                                            [_vShow, false],
                                                            [_vModelRadio, model.value]
                                                        ]),
                                                        _renderSlot(_ctx.$slots, "item", { item: item }, () => [
                                                            _createTextVNode(_toDisplayString(item.label), 1)
                                                        ])
                                                    ], 2)
                                                ]));
                                            }), 128))
                                        ]))
                                ], 64))
                                : (_ctx.loading)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(_ctx.$t('feedback.loading')), 1))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString(_ctx.$t('feedback.noFound')), 1))
                        ])
                    ], 4)
                ]),
                _: 3
            }, 8, ["disabled"]));
        };
    }
});
